// eslint-disable-next-line no-use-before-define
import React, {
  ReactNode, useContext,
} from 'react';

import {
  Flex,
} from '@chakra-ui/react';
import Head from 'next/head';
import { Metadata } from 'next';
import {
  IDisplayStatus,
  ITerm,
} from '../../lib/graphql';

import BasketFooter from '../../components/basket/basketFooter';
import isMobile from '../../utils/isMobile';
import { NavigationContext } from '../../lib/contexts/NavigationContext';
import Hero from '../../components/hero/hero';
import getTerms from '../../lib/graphQL/helpers/getTerms';
import Term from './components/Term';

interface ICourseListProps {
  terms: Array<ITerm>
}

export const metadata: Metadata = {
  title: 'Class Booking',
  description: 'Book your class now',
};

const BookingList = ({
  terms,
  // displayStatus,
}: ICourseListProps): ReactNode => {
  const { isChildSite } = useContext(NavigationContext);

  const titlePrefix = isChildSite ? 'Child' : 'Adult';
  const heroOpacity = isChildSite ? '20%' : '75%';
  const heroImage = isChildSite ? '/images/hero/child_2.jpg' : '/images/hero/DSC_7582.jpg';

  return (
    <Flex flexDirection="column">
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Hero
        title={`${titlePrefix} Class Booking`}
        image={heroImage}
        backgroundOpacity={heroOpacity}
        minH="150px"
        mt={0}
        mb={0}
      />
      <Flex width="100%" direction="column" alignItems="center">
        {
          terms.map((term) => (
            <Term term={term} />
          ))
        }
      </Flex>
      { isMobile() && <BasketFooter />}
    </Flex>
  );
};

export async function getServerSideProps({ query }) {
  let displayStatus = IDisplayStatus.Live;
  if (query.preview === IDisplayStatus.Preview) {
    displayStatus = IDisplayStatus.Preview;
  }
  const { terms } = await getTerms({ displayStatus });
  // const terms = [];

  return {
    props: {
      terms,
    },
  };
}

export default BookingList;
