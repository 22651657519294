import {
  Box, Button, GridItem, HStack, VStack,
} from '@chakra-ui/react';
import router from 'next/router';
import React from 'react';
import formatMoney from '../../../utils/money';
import ItemTags from '../../../components/item/ItemTags';
import formatter from '../../../utils/textUtils';
import {
  ICourseGroup,
  ICourseResult,
  ILocationEnum,
} from '../../../lib/graphql';
import ItemCard from '../../../components/item/itemCard';

const CourseList = ({ courses }: { courses: (ICourseResult | ICourseGroup)[] }) => courses?.map((item) => {
  let itemPrice;
  let itemTitle;
  let itemDescription;
  let itemImage;
  let actionButton;
  let imageOverlay;
  let imagePosition;
  let topBar;
  let topBarColor;

  if (!courses) return null;

  switch (item.__typename) {
    case 'CourseGroup':
      itemTitle = item.name;
      itemDescription = item.shortDescription;
      itemImage = item.image || '';
      itemPrice = `From ${formatMoney(item.minPrice)}`;
      imageOverlay = (
        <VStack height="100%" alignItems="baseline" flexDirection="column">
          <HStack>
            { item.courseTypes?.map((type) => (
              <Box key={`${item.id}${type}`}>
                <ItemTags.CourseTypeBadge
                  type={type}
                  size="md"
                  keyId={`${item.id}${type}`}
                />
              </Box>
            ))}
          </HStack>
        </VStack>
      );
      imagePosition = `${item.imagePosition.X || 50}% ${item.imagePosition.Y}%`;
      topBar = item.locations.reduce((agg, location) => {
        if (location) {
          if (agg.length > 0) {
            return `${agg} / ${formatter.removeUnderscores(location)}`;
          }
          return agg + formatter.removeUnderscores(location);
        }
        return agg;
      }, '');
      topBarColor = item.locations.includes(ILocationEnum.Zoom) ? 'purple.200' : 'pink.500';
      actionButton = <Button size="sm" w="100%" py={[2, 2, 'auto']} onClick={() => router.push(`/course/${item.id}`)} variant="solid" colorScheme="yellow" flex={1}>View Course</Button>;
      break;
    default:
      break;
  }

  return (
    <GridItem key={`bookingList${item.__typename + item.id}`}>
      <ItemCard
        title={itemTitle}
        description={itemDescription}
        image={itemImage}
        ActionButton={actionButton}
        ImageOverlay={imageOverlay}
        imagePosition={imagePosition}
        price={itemPrice}
        topbar={topBar}
        topBarColor={topBarColor}
      />
    </GridItem>
  );
});

export default CourseList;
