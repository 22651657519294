import {
  Box,
  Flex, Grid, Stack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { ITerm } from '../../../lib/graphql';
import CourseList from './CourseList';
import TermHeader from '../../../components/TermHeader';

const Term = ({ term }: { term: ITerm }): ReactNode => {
  if (!term) { return null; }
  return (
    <Flex width="100%" direction="column" alignItems="center">
      <Box my={10}>
        <TermHeader term={term} />
      </Box>

      {term?.courseGroups && (

        <Flex direction={['column', 'column', 'row']} width="90%" justifyContent="center">
          <Flex
            id="classList"
            flexDirection="column"
            width="100%"
            zIndex="1"
          >
            <Stack spacing={8}>
              <Grid
                gridTemplateColumns={['100%', '100%', 'repeat(2,50%)', 'repeat(3,300px)', 'repeat(4,300px)']}
                gap={5}
                justifyContent="center"
              >
                <CourseList courses={term.courseGroups} />
              </Grid>
            </Stack>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Term;
