import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { getDateString } from '../../utils/dateUtils';
import { ITerm } from '../../lib/graphql';

const Index = ({ term }: { term: ITerm }) => {
  if (!term) return null;

  const {
    holidays, id, name, startDate, endDate,
  } = term;

  return (
    <Box key={id} textAlign="center">
      <Heading as="h2">{name}</Heading>
      <Heading as="h3" mt={1} mb={4} size="md">
        {getDateString(new Date(startDate))}
        {' '}
        -
        {' '}
        {getDateString(new Date(endDate))}
      </Heading>
      { holidays?.map((holiday) => (
        <Box textAlign="center">
          <Heading as="h4" size="md">{holiday.name}</Heading>
          <Heading as="h5" mt={1} mb={4} size="sm">
            {getDateString(new Date(holiday.startDateTime))}
            {' '}
            -
            {' '}
            {getDateString(new Date(holiday.endDateTime))}
          </Heading>
        </Box>
      ))}
    </Box>
  );
};

export default Index;
